import * as React from "react"
//import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="PariahDAO | A Web3 Comms Project" />
    <section className="text-center container">
      <div className="row">
        <div className="col-lg-6 col-md-8 mx-auto">
          <StaticImage
            src="../images/pariah-logo.png"
            width={150}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Pariah DAO"
            className="img-fluid"
          />
          <h1 className="display-1 mt-2 mb-0">PARIAHDAO</h1>
          <p className="lead">A Web3 Comms Project</p>
        </div>
      </div>
    </section>
    <section className="bg-yellow py-5 section-footer">
      <div className="text-center container py-5">
        <p className="lead">
          <a href="mailto:hello@pariahdao.xyz">hello@pariahdao.xyz</a>
        </p>
      </div>
    </section>
  </Layout>
)

export default IndexPage
